import { OperationConfiguration } from '@models/operations-config-model';

export const operationsFieldDefinitions: OperationConfiguration[] = [
  {
    type: 'ITEM_REGISTRY',
    headerFields: [
      {
        headerName: '',
        fields: [
          { name: 'logisticPartner', type: 'String', label: 'Logistic Partner' },
          { name: 'status', type: 'String', label: 'Status' },
          { name: 'orderType', type: 'String', label: 'Order Type' },
          { name: 'client', type: 'String', label: 'Client' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'createdAt', type: 'String', label: 'Created At' },
          { name: 'updatedAt', type: 'String', label: 'Updated At' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'data.operationId', type: 'String', label: 'Operation ID' },
          { name: 'supplierCode', type: 'String', label: 'Supplier Code' },
        ],
      },
    ],
    accordionFields: [],
    rowFields: [
      { name: 'sku', type: 'String', label: 'SKU', class: 'sku-column' },
      { name: 'title', type: 'String', label: 'Title' },
      { name: 'category', type: 'String', label: 'Brand' },
      { name: 'unNumber', type: 'String', label: 'UN Number' },
      { name: 'price', type: 'String', label: 'Price', class: 'price-column' },
      { name: 'lotHandlingEnabled', type: 'String', label: 'Lot Handling', class: 'boolean-column' },
    ],
  },
  {
    type: 'INBOUND',
    // Defines the blue boxes in the header: is an array of Headers.
    // Each external array defines a box (max 6), which contains one or more fields.
    headerFields: [
      {
        headerName: '',
        fields: [
          { name: 'logisticPartner', type: 'String', label: 'Logistic Partner' },
          { name: 'status', type: 'String', label: 'Status' },
          { name: 'orderType', type: 'String', label: 'Order Type' },
          { name: 'client', type: 'String', label: 'Client' },
          { name: 'lotId', type: 'String', label: 'Lot ID' },
          { name: 'expirationDate', type: 'Date', label: 'Expiration Date' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'createdAt', type: 'String', label: 'Created At' },
          { name: 'updatedAt', type: 'String', label: 'Updated At' },
          { name: '', type: 'Number', label: 'Expected Quantity' },
          { name: '', type: 'Number', label: 'Received Quantity' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'data.operationId', type: 'String', label: 'Operation ID' },
          { name: 'data.customerOrderId', type: 'String', label: 'Customer Order Id' },
          { name: 'supplierCode', type: 'String', label: 'Supplier Code' },
          { name: 'warehouse', type: 'String', label: 'Warehouse' },
        ],
      },
    ],
    accordionFields: [],
    // Defines the table columns.
    rowFields: [
      { name: 'lineNumber', type: 'Number', label: '#', class: 'line-number-column' },
      { name: 'status', type: 'String', label: 'Status' },
      { name: 'sku', type: 'String', label: 'SKU', class: 'sku-column' },
      { name: 'expectedQuantity', type: 'Number', label: 'Expected Quantity', class: 'quantity-column' },
      { name: 'receivedQuantity', type: 'Number', label: 'Received Quantity', class: 'quantity-column' },
      { name: 'title', type: 'String', label: 'Title' },
      { name: 'warehouse', type: 'String', label: 'Warehouse' },
    ],
  },
  {
    type: 'OUTBOUND',
    headerFields: [
      {
        headerName: '',
        fields: [
          { name: 'logisticPartner', type: 'String', label: 'Logistic Partner' },
          { name: 'status', type: 'String', label: 'Status' },
          { name: 'orderType', type: 'String', label: 'Order Type' },
          { name: 'client', type: 'String', label: 'Client' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'documentDate', type: 'Date', label: 'Document Date', class: 'Date' },
          { name: 'createdAt', type: 'String', label: 'Created At' },
          { name: 'updatedAt', type: 'String', label: 'Updated At' },
          { name: '', type: 'Number', label: 'Expected Quantity' },
          { name: '', type: 'Number', label: 'Shipped Quantity' },
          { name: 'weightKg', type: 'String', label: 'Total Weight Kg' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'operationId', type: 'String', label: 'ShipmentId' },
          { name: 'customerOrderId', type: 'String', label: 'Customer Order Id' },
          { name: 'carrier', type: 'String', label: 'Carrier' },
          { name: 'supplierCode', type: 'String', label: 'Supplier Code' },
        ],
      },
    ],
    accordionFields: [
      {
        headerName: 'Customer Order Data',
        fields: [
          { name: 'customerOrderDate', type: 'Date', label: 'Order Date', class: 'Date' },
          { name: 'addressName', type: 'String', label: 'Name' },
          { name: 'customerAddress', type: 'String', label: 'Address' },
          { name: 'customerLocation', type: 'String', label: 'Location' },
          { name: 'customerZipCode', type: 'String', label: 'Zip Code' },
          { name: 'customerCountry', type: 'String', label: 'Country' },
          { name: 'latestDeliveryDate', type: 'String', label: 'Latest Delivery Date', class: 'Date' },
          { name: 'latestShipDate', type: 'String', label: 'Latest Shipment Date', class: 'Date' },
        ],
      },
    ],
    rowFields: [
      { name: 'sku', type: 'String', label: 'SKU', class: 'sku-column' },
      { name: 'status', type: 'String', label: 'Status' },
      { name: 'partnerStatus', type: 'String', label: 'Partner Status' },
      { name: 'orderQuantity', type: 'Number', label: 'Order Quantity' },
      { name: 'shippedQuantity', type: 'Number', label: 'Shipped Quantity' },
      { name: 'netValue', type: 'Number', label: 'Value' },
      { name: 'currencyCode', type: 'String', label: 'Currency' },
      { name: 'partnerShipmentId', type: 'String', label: 'Partner Id' },
      { name: 'carrier', type: 'String', label: 'Carrier' },
      { name: 'trackingNumber', type: 'String', label: 'Tracking Number', class: 'tracking-column' },
      { name: 'scanDate', type: 'Date', label: 'Scan Date', class: 'Date' },
      { name: 'trackingUrl', type: 'String', label: 'Tracking Url' },
      { name: 'shipperTracking', type: 'String', label: 'Shipper Tracking Number', class: 'tracking-column' },
      { name: 'unitWeightKg', type: 'String', label: 'Unit Weight Kg', class: 'weight-column' },
    ],
  },
  {
    type: 'TRANSFER_ORDER',
    headerFields: [
      {
        headerName: '',
        fields: [
          { name: 'status', type: 'String', label: 'Status' },
          { name: 'orderType', type: 'String', label: 'Order Type' },
          { name: 'client', type: 'String', label: 'Client' },
          { name: 'carrier', type: 'String', label: 'Carrier' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'documentDate', type: 'String', label: 'Document Date', class: 'Date' },
          { name: 'createdAt', type: 'String', label: 'Created At' },
          { name: 'updatedAt', type: 'String', label: 'Updated At' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'logisticPartner', type: 'String', label: 'Logistic Partner' },
          { name: 'operationId', type: 'String', label: 'ShipmentId' },
          { name: 'customerOrderId', type: 'String', label: 'Customer Order Id' },
        ],
      },
    ],
    accordionFields: [
      {
        headerName: 'Customer Order Data',
        fields: [
          { name: 'customerOrderDate', type: 'String', label: 'Order Date', class: 'Date' },
          { name: 'addressName', type: 'String', label: 'Name' },
          { name: 'customerAddress', type: 'String', label: 'Address' },
          { name: 'customerLocation', type: 'String', label: 'Location' },
          { name: 'customerZipCode', type: 'String', label: 'Zip Code' },
          { name: 'customerCountry', type: 'String', label: 'Country' },
        ],
      },
    ],
    rowFields: [
      { name: 'sku', type: 'String', label: 'SKU', class: 'sku-column' },
      { name: 'status', type: 'String', label: 'Status' },
      { name: 'partnerStatus', type: 'String', label: 'Partner Status' },
      { name: 'orderQuantity', type: 'Number', label: 'Order Quantity' },
      { name: 'bulkConfirmedQuantity', type: 'Number', label: 'Shipped Quantity' },
      { name: 'netValue', type: 'Number', label: 'Value' },
      { name: 'currencyCode', type: 'String', label: 'Currency' },
      { name: 'partnerShipmentId', type: 'String', label: 'Partner Id' },
      { name: 'carrier', type: 'String', label: 'Carrier' },
      { name: 'bulkShipmentDate', type: 'String', label: 'Shipment Date', class: 'Date' },
      { name: 'bulkShipmentNumber', type: 'String', label: 'Tracking Number', class: 'tracking-column' },
      { name: 'bulkShipmentTrackingUrl', type: 'String', label: 'Tracking Url', class: 'tracking-column' },
    ],
  },
  {
    type: 'STOCK_MOVEMENT',
    headerFields: [
      {
        headerName: '',
        fields: [
          { name: 'status', type: 'String', label: 'Status' },
          { name: 'orderType', type: 'String', label: 'Order Type' },
          { name: 'client', type: 'String', label: 'Client' },
        ],
      },
      {
        headerName: '',
        fields: [
          { name: 'documentDate', type: 'String', label: 'Document Date', class: 'Date' },
          { name: 'createdAt', type: 'String', label: 'Created At' },
          { name: 'updatedAt', type: 'String', label: 'Updated At' },
        ],
      },
      {
        headerName: 'Logistic Partner',
        fields: [
          { name: 'logisticPartner.code', type: 'String', label: 'Code' },
          { name: 'logisticPartner.name', type: 'String', label: 'Name' },
        ],
      },
    ],
    accordionFields: [],
    rowFields: [
      { name: 'lineNumber', type: 'Number', label: '#' },
      { name: 'sku', type: 'String', label: 'SKU', class: 'sku-column' },
      { name: 'warehouseIn', type: 'String', label: 'Warehouse In' },
      { name: 'warehouseOut', type: 'String', label: 'Warehouse Out' },
      { name: 'expectedQuantity', type: 'Number', label: 'Expected Quantity' },
      { name: 'actualQuantity', type: 'Number', label: 'Actual Quantity' },
    ],
  },
];

export const feedTypesDefinition = [
  'ITEM_REGISTRY',
  'STOCK_BALANCE',
  'INBOUND',
  'INBOUND_CONFIRMATION',
  'OUTBOUND',
  'OUTBOUND_CONFIRMATION',
  'STOCK_MOVEMENT',
  'STOCK_MOVEMENT_CONFIRMATION',
];

export const confirmationFeeds = ['INBOUND_CONFIRMATION', 'OUTBOUND_CONFIRMATION', 'STOCK_MOVEMENT_CONFIRMATION'];
