<div class="container-fluid">
  <ng-container *ngIf="!loading">
    <div class="row my-3 align-items-center">
      <div class="col-md-10 d-flex align-items-center">
        <h2 class="fst-italic text-secondary mb-0">{{ operation.type }} Operation: {{ operation.documentNumber }}</h2>
        <button *ngIf="hasBridgetSuperPermission" class="btn-icon ms-3" [matMenuTriggerFor]="operationActions">
          <i class="fa fa-wrench"></i>
        </button>
        <mat-menu #operationActions="matMenu">
          <button mat-menu-item (click)="pollOperation()"><i class="fa fa-cloud-download margin-right-6"></i> Poll operation</button>
          <button mat-menu-item (click)="sendOperation()"><i class="fa fa-paper-plane margin-right-6"></i> Send to partner</button>
          <button mat-menu-item (click)="moveOperationToNew()"><i class="fa fa-undo margin-right-6"></i> Move back to New</button>
          <button mat-menu-item *ngIf="operation.type === 'OUTBOUND'" (click)="setTrackingData()">
            <i class="fa fa-truck margin-right-6"></i> Set tracking data
          </button>
        </mat-menu>
      </div>

      <div class="col-md-2 text-end">
        <button
          *ngIf="operation?.data?.shippyProErrorMessage && (hasBridgetAdministrationPermission | async)"
          class="btn-icon error-icon"
          matTooltip="Shippypro Error"
          (click)="openShippyproErrorModal()"
        >
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </button>

        <button class="btn-custom" [matMenuTriggerFor]="operationFeeds" *ngIf="hasFeedFiles">
          <i class="fa fa-file-text" aria-hidden="true"></i> Feeds
        </button>
        <mat-menu #operationFeeds="matMenu">
          <button
            (click)="navigateToFile($event, operation.operationFeed.operationFeedUrl)"
            mat-menu-item
            *ngIf="!(operation?.operationFeed?.operationFeedUrl | isNullOrUndefinedOrEmptyString)"
          >
            <i class="fa fa-download padding-right-5" aria-hidden="true"></i>
            Input File
          </button>
          <button
            (click)="navigateToFile($event, operation.operationFeed.outputFeedUrl)"
            mat-menu-item
            *ngIf="!(operation?.operationFeed?.outputFeedUrl | isNullOrUndefinedOrEmptyString)"
          >
            <i class="fa fa-upload padding-right-5" aria-hidden="true"></i>
            Output Feed
          </button>
          <button
            (click)="navigateToFile($event, operation.confirmationFeed.confirmationFeedUrl)"
            mat-menu-item
            *ngIf="!(operation?.confirmationFeed?.confirmationFeedUrl | isNullOrUndefinedOrEmptyString)"
          >
            <i class="fa fa-check-square-o padding-right-5" aria-hidden="true"></i>
            Confirmation Feed
          </button>
        </mat-menu>

        <button class="btn-custom margin-right-left-15" (click)="goBack()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
        </button>
      </div>
    </div>

    <div class="row mx-0">
      <div class="bluebox" *ngFor="let h of headers; let i = index">
        <h4 *ngIf="h.headerName">
          <b>{{ h.headerName || '' }}</b>
        </h4>

        <p class="key-table-fixed" *ngFor="let field of h.fields">
          <b class="margin-right-6"> {{ field.label }}: </b>
          <ng-container [ngSwitch]="field.label">
            <ng-container *ngSwitchCase="'Expected Quantity'">{{ expectedQuantityValue }}</ng-container>
            <ng-container *ngSwitchCase="'Received Quantity'">{{ receivedQuantityValue }}</ng-container>
            <ng-container *ngSwitchCase="'Shipped Quantity'">{{ receivedQuantityValue }}</ng-container>
            <ng-template ngSwitchDefault>{{ operation | readProperty: field }}</ng-template>
          </ng-container>
        </p>
      </div>
    </div>
  </ng-container>
</div>

<app-operation-details-accordion
  *ngFor="let accordion of accordions"
  [accordion]="accordion"
  [operation]="operation"
></app-operation-details-accordion>

<!--<hr>-->

<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-md-6">
      <button class="btn-custom" *ngIf="isExportable" (click)="exportOperation()">
        <i class="fa fa-download margin-right-6" aria-hidden="true"></i> Export in CSV
      </button>
    </div>
    <div class="col-md-6">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        class="container"
        (page)="loadPage()"
      >
      </mat-paginator>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="table-container mb-5">
      <table
        mat-table
        id="table-pos-details"
        [dataSource]="dataSource"
        matSort
        (matSortChange)="onSort()"
        [ngClass]="'table-' + operation.type"
      >
        <ng-container *ngFor="let colField of operationRowColumns" [matColumnDef]="colField.name">
          <th mat-header-cell [ngClass]="colField.class ? colField.class : ''" *matHeaderCellDef [mat-sort-header]="colField.name">
            {{ colField.label }}
          </th>
          <td mat-cell [ngClass]="colField.class ? colField.class : ''" *matCellDef="let row">
            <p>{{ row | readProperty: colField }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="messages">
          <th mat-header-cell class="messages-column" *matHeaderCellDef></th>
          <td mat-cell class="messages-column" *matCellDef="let row">
            <i
              *ngIf="!(row.messages | isNullOrUndefinedOrEmptyString)"
              (click)="openMessages(row)"
              class="fa fa-exclamation-triangle table-icon"
              aria-hidden="true"
            ></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="superActions">
          <th mat-header-cell class="button-column" *matHeaderCellDef></th>
          <td mat-cell class="button-column" *matCellDef="let row">
            <button class="btn-icon" [matMenuTriggerFor]="rowActions"><i class="fa fa-wrench"></i></button>

            <mat-menu #rowActions="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="rowStatuses"><i class="fa fa-retweet margin-right-6"></i> Force status</button>
              <mat-menu #rowStatuses="matMenu"
                ><button mat-menu-item *ngFor="let s of operationRowStatuses" (click)="changeRowStatus(row.id, s)">
                  {{ s }}
                </button></mat-menu
              >
            </mat-menu>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            'bg-danger':
              (operation.type === 'OUTBOUND' && row.data.orderQuantity !== row.data.shippedQuantity) ||
              (operation.type === 'INBOUND' && row.data.expectedQuantity !== row.data.receivedQuantity),
          }"
        ></mat-row>
      </table>
    </div>
  </ng-container>
</div>
